import io from "socket.io-client"

// const dashboardSocket = io("https://loadbalancer.paperplane.health:8300/doctor")
const dashboardSocket = io("https://pplane-api-prod.paperplane.health/doctor", {
  transports: ["websocket", "polling"]
})
dashboardSocket.on("connect", () => {
  console.log(dashboardSocket.id)
})
dashboardSocket.on("disconnect", () => {
  console.log(dashboardSocket.id)
})

export default dashboardSocket
